import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import Swal from 'sweetalert2';
import axios from 'axios';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed font-black`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-[#ee2833]`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const token = window.location.pathname.split('/')[2];

export default ({
  description = "Es necesario que su contraseña incluya al menos 8 caracteres, una letra mayúscula, una letra minúscula y un número.",
  submitButtonText = "Cambiar Contraseña",
  textOnLeft = true,
}) => {
  
  const [new_password, setNewPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (new_password === '' || confirm_password === '') {
      Swal.fire({
        title: 'Error',
        text: 'Debes llenar todos los campos',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    if (new_password !== confirm_password) {
      Swal.fire({
        title: 'Error',
        text: 'Las contraseñas no coinciden',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    if (new_password.length < 8) {
      Swal.fire({
        title: 'Error',
        text: 'La contraseña debe tener al menos 8 caracteres',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }
    axios.post(`https://ofertaloapp.com:444/api/users/reset-password/${token}/`, {
      new_password: new_password,
      confirm_password: confirm_password
    })
    .then((response) => {
      Swal.fire({
        title: 'Contraseña Cambiada',
        text: 'Tu contraseña ha sido cambiada exitosamente, ya puedes iniciar sesión en la app',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
      setNewPassword('');
      setConfirmPassword('');
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    })
    .catch((error) => {
      Swal.fire({
        title: 'Error',
        text: 'Ocurrio un error al cambiar tu contraseña',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    });
  }
      
  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>Reestablecer Contraseña</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Input type="password" name="new_password" placeholder="Contraseña" onChange={e => setNewPassword(e.target.value)} />
              <Input type="password" name="confirm_password" placeholder="Confirmar Contraseña" onChange={e => setConfirmPassword(e.target.value)} />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
