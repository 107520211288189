import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import pdf from "../docs/aviso-de-privacidad-integral.pdf";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Aviso de Privacidad" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Ultima Actualización: Agosto del 2024</p>

            <p>
              ALPHA DESERT S.A.P.I. DE C.V. con RFC ADE240719AH6, y domicilio en Presa malpaso 11, Colonia El
              Ranchito, C.P. 83050, Hermosillo, Sonora, México con página de Internet <a href="https://ofertaloapp.com">ofertaloapp.com</a>, es responsable del uso y protección de sus datos personales, y al
              respecto le informamos lo siguiente:
            </p>

            <h1>¿PARA QUE FINES UTILIZAMOS SUS DATOS PERSONALES?</h1>
    
            <h3 style={{ marginBottom: '15px' }}>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita: </h3>
            <ul>
              <li>
                Publicidad.
              </li>
              <li>
                Trámites de facturación.
              </li>
              <li>
                Otorgar los productos y servicios contratados.
              </li>
            </ul>
            <h3 style={{ marginBottom: '15px' }}>De Manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:</h3>
            <ul>
              <li>
                Manejo de programas de lealtad para clientes.
              </li>
              <li>
                Realizar Rifas y/o concursos.
              </li>{" "}
              <li>
                Envío de comunicaciones y/o imágenes promocionales de productos y servicios.
              </li>
            </ul>

            <p>
              En caso de que no desee que sus datos personales sean tratados para estos
              fines secundarios, nos puede comunicar lo anterior enviando un correo
              electrónico contacto@ofertaloapp.com requiriendo una solicitud de derechos
              ARCO.
            </p>
            <p>
              La negativa para el uso de sus datos personales para estas finalidades no
              podrá ser motivo para que le neguemos los servicios y productos que solicita o
              contrata con nosotros.
            </p>

            <h1>¿DÓNDE PUEDO CONSULTAR EL AVISO DE PRIVACIDAD INTEGRAL?</h1>
            <p>
              Para conocer mayor información sobre los términos y condiciones en que serán
              tratados sus datos personales, así como los terceros con quienes compartimos
              su información personal y la forma en que ejercemos sus derechos ARCO,
              puede descargar nuestro aviso de privacidad integral en el siguiente enlace:
              <a href={pdf} target="_blank" rel="noopener noreferrer" style={{ color: "#ee2833" }}>
              {" "}Descargar Aviso de Privacidad Integral
            </a>
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
