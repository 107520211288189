import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/job-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-lg font-bold text-white`;
  const HighlightedText = tw.span`bg-[#ee2833] text-white px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Compra, vende y encuentra <HighlightedText>lo que necesitas.</HighlightedText></>}
        description="Ofértalo es una plataforma donde puedes comprar y vender productos, ofrecer o contratar servicios, ofrecer o buscar Empleo. 
        ¡Todo en un solo lugar!."
        imageSrc="https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        watchVideoButtonText="Conoce la plataforma"
      />
      <MainFeature
        subheading={<Subheading>¿Que es Ofertalo?</Subheading>}
        heading={
          <>
            Somos una plataforma de compra y venta de productos y servicios,<HighlightedText>asi como de busqueda de empleo.</HighlightedText>
          </>
        }
        description={
          <Description>
            Ofertalo surgio con la idea de ofrecer una plataforma donde puedas comprar y vender productos y servicios, asi como encontrar empleo. 
            <br />
            <br />
            Ya que en la actualidad, la mayoria de las personas buscan realizar compras y ventas por internet, asi como encontrar empleo.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc={
          "https://images.unsplash.com/photo-1460306855393-0410f61241c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <Features
        heading={
          <>
            Te ofrecemos un <HighlightedText> servicio 3 en 1.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "App tipo Marketplace",
            description: "Donde podras comprar y vender todo tipo de productos.",
          },
          {
            imageSrc: chefIconImageSrc,
            title: "App de Servicios",
            description: "Aqui podras encontrar y ofrecer servicios de todo tipo.",
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "App de Empleos",
            description: "¿Buscas empleo? Aqui podras encontrarlo. ¿Ofreces empleo? Aqui podras publicarlo.",
            url: "https://reddit.com"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <DownloadApp
        text={<>Unete a nuestra plataforma y <HighlightedText>descarga la app</HighlightedText> para empezar a comprar y publicar.</>}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
