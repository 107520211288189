import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terminos y Condiciones" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Ultima Actualización: Agosto de 2024</p>

            <h1>1. Descripción.</h1>
            <p>OFERTALO con domicilio en Av. Presa Mal Paso #11, Colonia El Ranchito en Hermosillo, Sonora; pone a su disposición el presente documento que establece los términos y condiciones de uso de la plataforma digital referida en estos mismos, que en lo sucesivo se le denominará “Plataforma”.</p>

            <h1>2. Aceptación de los servicios.</h1>
            <p>
              Al acceder, usar o descargar la plataforma de OFERTALO, usted reconoce haber leído, comprendido y aceptado los presentes términos y condiciones, así como el aviso de privacidad de OFERTALO y los de terceros vinculados al mismo; o cualquier otro documento referido al tenor de este instrumento.
            </p>

            <h1>3. Definiciones.</h1>
            <p>Para los efectos de los presentes términos y condiciones, se entenderá por:</p>
            <ul>
              <li>
                <strong>OFERTALO:</strong> ALPHA DESERT S.A.PI. DE C.V. en carácter de propietaria, administradora, soporte técnico, y licenciante de la Plataforma.
              </li>
              <li>
                <strong>Plataforma:</strong> Es la plataforma digital en forma de sitio web y/o aplicación descargable, que permite a sus usuarios la oferta, búsqueda y compra venta de productos, búsqueda y oferta de empleos, geolocalización de establecimientos comestibles y contratación de servicios.
              </li>
              <li>
                <strong>Usuario:</strong> Se refiere a cualquier persona que utilice la Plataforma de OFERTALO.
              </li>
              <li>
                <strong>Cuenta:</strong> Es el perfil y panel de control del Usuario para el acceso y gestión de los servicios disponibles en la Plataforma.
              </li>
              <li>
                <strong>Servicios:</strong> se refiere de manera indistinta a cualquiera de los productos y servicios que ofrece OFERTALO a través de la Plataforma. Dichos servicios o productos son distintos a los que ofrezcan los usuarios como parte de su actividad en la plataforma de OFERTALO.
              </li>
            </ul>

            <h1>4. ¿Qué es la plataforma de OFERTALO?</h1>
            <p>
              OFERTALO provee una plataforma digital en forma de Marketplace, que permite a sus usuarios, interactuar para la oferta y búsqueda de artículos, así como de empleos, prestaciones de servicios, y la localización de establecimientos de comidas. La plataforma de OFERTALO en ningún momento se considerará una plataforma para la contratación de ninguno de los productos o servicios ofrecidos por sus usuarios, en el entendido de que la contratación o compraventa de los mismos quedará reservada exclusivamente entre los usuarios de manera particular sin ninguna injerencia o intermediación por parte de OFERTALO, ya que la participación de este último se entenderá únicamente limitada a la provisión de un espacio para la oferta y búsqueda de los mismos. 
            </p>

            <h1>5. Registro y elegibilidad.</h1>
            <p>
              <strong>5.1.-</strong> Para poder disponer de los servicios de la plataforma, se deberá crear una cuenta de usuario. Al crear la cuenta, se acepta proporcionar la información precisa y completa, y mantener la información actualizada en todo momento.
            </p>
            <p>
              <strong>5.2.-</strong> Al registrar una cuenta de usuario y aceptar los Términos y Condiciones, el Usuario tiene acceso a un panel de control que le permitirá ingresar a la plataforma y utilizar los diferentes servicios que ofrece, tales como ofrecimiento de productos, prestaciones de servicios, empleos, geolocalización de establecimientos de comida y la búsqueda de los mismos.
            </p>
            <p>
              <strong>5.3.-</strong> La información requerida en el apartado de Registro de Usuario, y en general para el uso y disposición de la Plataforma, será en todo momento conforme a nuestra política de tratamiento de datos personales, según lo necesario para la provisión de los servicios de OFERTALO. Para el tratamiento de sus datos personales, consulte nuestro Aviso de Privacidad.
            </p>
            <p>
              <strong>5.4.-</strong> Al utilizar OFERTALO usted declara bajo protesta de decir verdad que será con fines lícitos y legítimos. Todo usuario de la plataforma manifiesta bajo protesta de decir verdad, contar con al menos 18 años edad y no tener impedimento legal alguno para contratar al tenor del presente instrumento.
            </p>
            <p>
              <strong>5.5.-</strong> El usuario reconoce que algunas de las funciones de la plataforma requieren de compartir ubicación o domicilio, que, de no hacerlo, podrá significar la imposibilidad parcial o total de acceso a los servicios de la plataforma. Al respecto, OFERTALO se compromete a no solicitar la ubicación o domicilio del usuario salvo cuando sea estrictamente necesario para la disposición de los servicios de la plataforma. Dicha geolocalización no se refiere a la posible divulgación de domicilio o ubicación que el usuario realice para interacción con otro usuario en la plataforma, sino para acceder a los servicios de la plataforma.
            </p>
            <p>
              <strong>5.6.-</strong> OFERTALO podrá poner en marcha medidas de verificación de las cuentas de usuario, así como sobre la legitimidad y formalidad del usuario sin embargo en ningún momento podrá ser responsable por la autenticidad, legitimidad, licitud, regularidad administrativa, personalidad ni ninguna otra similar o análoga de algún usuario, cuenta de usuario o bien los productos, servicios, empleos o comida que se ofrezca a través de los mismos.
            </p>

            <h1>6. Disponibilidad de los servicios.</h1>
            <p>
              OFERTALO manifiesta que, con el propósito de mejorar la calidad de los servicios, estos podrán variar de un momento a otro. Por lo que no se responsabiliza por la insatisfacción de oferta que pudiera usted experimentar. 
            </p>
            <p>
              La plataforma de OFERTALO estará de manera general disponible a través de su versión de aplicación digital descargable, sin embargo, OFERTALO podrá poner a su disposición versiones de la misma en modalidad de sitio web
            </p>
            <p>
              OFERTALO se reservará el derecho a negar los servicios que contravengan los presentes términos o bien si existiera la sospecha de contravención a los mismos. OFERTALO se reservará el derecho a negar los servicios que contravengan los presentes términos o bien si existiera la sospecha de contravención a los mismos. 
            </p>

            <h1>7. Métodos de pago.</h1>
            <p>
              <strong>7.1.-</strong> En caso de contratar cualquiera de los servicios que generen un costo por parte de OFERTALO, tales como los servicios de publicidad de la plataforma, OFERTALO podrá utilizar procesadores de pago externos y ajenos al mismo. Al respecto, usted manifiesta estar de acuerdo con los términos y condiciones, así como los avisos de privacidad de dichos procesadores de pago previo a la realización de pago.
            </p>
            <p>
              <strong>7.2.-</strong> Usted acepta que los métodos podrán variar de acuerdo a aquellos que acepte el procesador de pago de que se trate en el momento de la contratación.
            </p>
            <p>
              <strong>7.3.-</strong> Usted se obliga a abstenerse de cualquier queja, reclamación y/o procedimiento derivado de la insatisfacción en los métodos de pago y/o procesadores de pago que responsabilicen a OFERTALO cuando utilice procesadores de pago externos, no obstante podrá contactar a OFERTALO al correo contacto@ofertaloapp.com o bien mediante llamada o mensaje al número 66 24 53 9177 con el propósito de establecer una solución entorno al pago de la contratación.
            </p>
            <p>
              <strong>7.4.-</strong> El usuario reconoce que OFERTALO en ningún momento fungirá como intermediario de ningún pago para la compra o contratación de los productos, servicios, alimentos ni ningún otro ofrecido por usuarios en la plataforma, en el entendido de que dichos pagos se realizarán directamente entre los usuarios o bajo el esquema que libremente acuerden entre los mismos, ya que queda claro que OFERTALO únicamente ofrece la plataforma como sitio de interacción para la búsqueda y libre contratación de productos, servicios y empleos entre los usuarios.
            </p>

            <h1>8. Facturación.</h1>
            <p>
              Usted podrá solicitar solicitar la factura correspondiente a cualquier pago realizado a favor de OFERTALO durante el mes de contratación de que se trate. Al respecto se obliga a proporcionar toda y cualquier información que le sea requerida para tal efecto. El Usuario manifiesta que sus recursos y/o activos no provienen directamente o indirectamente de actividades ilícitas, o de cualquier otra actividad o acto que pudiese favorecer sobre la actualización de los supuestos delitos de financiamiento al terrorismo o recursos de procedencia ilícita. Así como aquellos recursos, derechos o bienes de cualquier naturaleza que se emplearán para solventar o cumplimentar el objeto del presente no provienen, actualizan o se asemejan al ilícito contemplado en el artículo 400 Bis del Código Penal Federal.
            </p>

            <h1>9. Políticas de Uso.</h1>
            <p>
              Al acceder o usar la Plataforma, usted se hace responsable por el cumplimiento de las políticas de uso. OFERTALO se reserva el derecho a suspender temporal o definitivamente a cualquier tipo de usuario el acceso a la Plataforma previa notificación, por contravención a estos Términos y Condiciones o por actos que perjudican o han perjudicado la experiencia de algún usuario, y/o público en general; posteriormente el usuario podrán optar con iniciar un periodo de investigación y aclaración para su posible reactivación mediante solicitud de aclaración al correo contacto@ofertaloapp.com en un periodo no superior a 15 días hábiles posteriores a la suspensión de la cuenta de usuario. Al respecto, OFERTALO deberá responder su resolución en un plazo no mayor a 15 días hábiles, no obstante, OFERTALO se reserva el derecho a aplazar el periodo de análisis y resolución respectivo de manera indefinida si así lo estimara necesario mediante aviso de dicho aplazamiento.
            </p>

            <h1>10. Limitación territorial.</h1>
            <p>
              Los servicios de OFERTALO estarán disponibles únicamente en México, OFERTALO se reserva el derecho de modificar la presente cláusula para limitar, expandir o en general modificar el territorio de uso de la plataforma.
            </p>

            <h1>11. Prohibiciones.</h1>
            <p>
              <strong>11.1.-</strong> No será permitida la oferta ni solicitud entre los usuarios de servicios ilícitos, o en general que atenten contra la privacidad, el honor o la dignidad de las personas. Asimismo, todo aquel que prohíba la legislación mexicana, así como incluido sin limitación, pornografía, intercambio sexual, contenido inmoral, aquél que beneficie, simule o represente apologías del delito, daño autoinflingido y/o suicidio, o aquél que atente contra la salud o seguridad pública. De igual manera estará prohibida la solicitud de productos o servicios o subida de información que violen propiedad intelectual, acuerdos de confidencialidad y/o exclusividad comercial.
            </p>
            <p>
              <strong>11.2.-</strong> En el caso de la prestación de servicios por parte de los usuarios que se ofrecen en la plataforma, queda expresamente prohibido a través del presente documento para los usuarios, el ofrecimiento de prestación de servicios que según las leyes aplicables requieran de Cédula Profesional. El ofrecimiento o solicitud de cualquiera de este tipo de servicios podrá dar como resultado la eliminación inmediata de la publicación, así como la cancelación temporal o definitiva del usuario correspondiente. OFERTALO de ninguna manera ofrecerá garantía ni será responsable en el sentido más amplio posible por la calidad, calificación, resultados, daños, perjuicios ni ninguna otra situación similar o análoga por los productos y/o servicios ofertados por los usuarios en la Plataforma, en el entendido de que dichos servicios son libremente ofrecidos y contratados por los usuarios. El usuario se obliga a contar con las certificaciones, aptitudes y requerimientos oficiales aplicables para la prestación del servicio y/o comercialización del producto que ofrezca en la plataforma, no obstante, OFERTALO no será responsable por ninguna omisión de los usuarios en dicho sentido. 
            </p>
            <p>
              <strong>11.3.-</strong> Quedará prohibida la oferta o solicitud de empleo realizada por algún usuario en la plataforma, y que contravenga disposiciones oficiales en materia laboral, los presentes términos o cualquier legislación aplicable.
            </p>
            <p>
              <strong>11.4.-</strong> No será permitida la oferta, promoción ni solicitud de armas, municiones, explosivos, materiales biológicos, pirotécnica, droga, medicamentos, bebidas alcohólicas, productos de tabaco o con nicotina y parafernalia relacionada y/o sustancias nocivas.
            </p>
            <p>
              <strong>11.5.-</strong> Las publicaciones no pueden promocionar la compra, venta, renta, ni monta de animales o productos de animales, así como tampoco de tierras en áreas de conservación ecológica.
            </p>
            <p>
              <strong>11.6.-</strong> De manera expresa estará prohibido el ofrecimiento de cualquier servicio de atención a la salud.
            </p>
            <p>
              <strong>11.7.-</strong> Estarán prohibidas las publicaciones no pueden promocionar la compra, la venta ni la facilitación de juegos de azar online o presencial por dinero o equivalente de dinero, incluidas las monedas digitales. Los juegos de azar online incluyen juegos, apuestas, loterías, rifas, casinos, deportes de fantasía, bingos, póquer y sorteos en un entorno online.
            </p>

            <p>
              Cualquier publicación en la plataforma que contravenga cualquiera de las disposiciones en la presente cláusula podrá ser eliminada inmediatamente por OFERTALO y posteriormente cancelada temporal o definitivamente la cuenta de usuario de quien la hubiere hecho. Usted acepta  que no existirán reembolsos de ninguna clase al ser sujeto a suspensión o cancelación de usuario, así como a cualquier remoción de publicaciones por contravención a cualquier infracción de los presentes términos y condiciones.
            </p>

            <h1>12. Identidad.</h1>
            <p>
              Queda prohibido la usurpación de identidad o derechos comerciales mediante la creación de cuenta(s) de usuario que simulen o pretendan ser una persona que no hubiese consentido para tal efecto.
            </p>

            <h1>13. Responsabilidad por terceros.</h1>
            <p>
              Usted acepta responsabilidad por cualquier persona que con o sin su autorización utilice su usuario en el entendido de que es su responsabilidad resguardar adecuadamente los usuarios, correos electrónicos y contraseñas dados de alta en la Plataforma. Dicha responsabilidad comprende exentar de responsabilidad a OFERTALO, sus colaboradores, proveedores, trabajadores, directivos, clientes y licenciantes por daños, perjuicios, pérdidas, gastos y costas judiciales y cualquier otra afectación derivada de su cuenta de usuario, actividad y/o uso de la plataforma.
            </p>

            <h1>14. Uso legítimo del Servicio.</h1>
            <p>
              Serán prohibidos los actos tendientes a realizar ingeniería inversa, hackeo, desmantelamiento, interferencia, modificación, copia, traducción, y/o alteración de las características, funcionalidad, integridad, y/o desempeño de la Plataforma. Asimismo, los actos tendientes a obtener acceso no autorizado a la Plataforma y/o sistemas relacionados; así como violar, eludir, remover, desactivar o de cualquier forma engañar cualquier software de protección o mecanismos de seguridad de la misma.
            </p>

            <h1>15. Violaciones en seguridad.</h1>

            <h2>15.1.- Notificaciones.</h2>
            <p>
              Usted deberá en el marco de 24 horas notificar a OFERTALO vía correo electrónico a     contacto@ofertaloapp.com de cualquier sospecha o hecho confirmado de violaciones de seguridad con lo que respecta a su cuenta y/o registro. Incluyendo sin limitación, el robo, pérdida, y/o modificación de cuentas, registros y contraseñas.
            </p>
            <h2>15.2.- Resguardo adecuado.</h2>
            <p>
              OFERTALO resguardará los datos personales e información en general puesta a su disposición. No obstante, ninguna medida de seguridad digital o física garantiza por completo la impenetrabilidad de la Plataforma. Por tal razón, usted en calidad de usuario, público en general y/o cualquier modalidad en la que se relacione con OFERTALO acepta exentar de responsabilidad por todo tipo de violación en seguridad, robo o pérdida de información, o cualquier atentado contra la integridad de la Plataforma o los datos personales, incluyendo los casos de negligencia de OFERTALO, sus trabajadores, directivos, socios, colaboradores, clientes y/o licenciantes.
            </p>

            <h2>15.3.- Exención de responsabilidad e indemnización.</h2>
            <p>
              Toda persona que acceda, use, descargue o navegue en la plataforma acepta exentar de responsabilidad e indemnizar a OFERTALO, sus trabajadores, colaboradores, directivos, licenciantes y/o usuarios por cualquier tipo de responsabilidad, daño, pérdida, o perjuicio derivado del mal uso en su cuenta por parte de usted sus trabajadores, directivos, proveedores, colaboradores, clientes, socios, y/o cualquier otra persona actuando a través de una cuenta de usuario con o sin consentimiento del titular de la cuenta, en el entendido que OFERTALO pone a su disposición en buena fe la Plataforma para los fines y alcances establecidos en los presentes términos y condiciones. Dicha exención referida será extensiva sin limitación para la responsabilidad civil, administrativa, penal, mercantil y cualquiera otra que pudiera generarse.
            </p>

            <h2>15.4.- Exención de responsabilidades en seguridad.</h2>
            <p>
              En adición al punto 15.3 en la más amplia extensión permitida por la legislación aplicable, OFERTALO, sus trabajadores, directivos, colaboradores no serán responsables por daños indirectos, incidentales, consecuenciales, o por la pérdida de dinero, información o cualquier otro bien tangible o intangible que resulte del uso o inhabilitación del uso de la Plataforma. OFERTALO no se responsabiliza de que en algún momento la información suministrada por cualquier persona sea borrada o que ocurra un fallo durante el proceso de almacenamiento de la misma, con excepción de la responsabilidad de notificación de lo sucedido conforme a la legislación de protección de datos personales aplicable.
            </p>
            <p>
              Así mismo, toda persona que acceda, use, descargue o navegue en la plataforma acepta exentar de cualquier responsabilidad legal e indemnizar a OFERTALO, sus trabajadores, colaboradores, directivos, licenciantes y/o clientes, por incumplimiento, malas prácticas, mala calidad en los servicios, etc., derivados del ofrecimiento, búsqueda ejercicio de prestación de servicios anunciados a través de la plataforma.
            </p>

            <h2>15.5.- Exención por fallas ajenas a OFERTALO.</h2>
            <p>
              Toda persona que use y/o tenga alguna cuenta registrada en la Plataforma es responsable de contar con los servicios y equipos necesarios para la navegación por internet y para acceder a la Plataforma. En caso de cualquier incidencia o dificultad para acceder a la cuenta registrada, el usuario puede ponerse en contacto con OFERTALO al correo electrónico contacto@ofertaloapp.com que procederá a analizar la incidencia y dará indicaciones al interesado acerca de cómo resolverla en el plazo más breve posible. OFERTALO no responderá en caso de interrupciones del servicio, errores de conexión, falta de disponibilidad o deficiencias en el servicio de acceso a internet, ni por interrupciones de la red de internet o por cualquier otra razón ajena al control de la plataforma. OFERTALO no se hace responsable de los errores de seguridad que se puedan producir ni de los daños que puedan causarse al sistema informático (hardware y software), a los ficheros o documentos almacenados en el mismo de  ninguna persona que utilice la Plataforma, como consecuencia de la presencia de un virus en el sistema informático del usuario o de cualquier persona que sea utilizado para la conexión a los servicios y contenidos de la Plataforma, o del uso de versiones no actualizadas de la misma en su caso.
            </p>

            <h1>16. No competencia.</h1>
            <p>Usted se obliga a abstenerse de utilizar la Plataforma para desarrollar un producto o servicio similar con el que compita; así como copiar ideas, funciones, imágenes y diseño de la Plataforma ya sea que consten propiedad en el hecho o derecho de OFERTALO.</p>

            <h1>17. T&C y Avisos de Privacidad.</h1>
            <p>
              Usted reconoce haber leído y estar de acuerdo con los Términos y Condiciones de uso, así como el Avisos de Privacidad de OFERTALO y demás avisos y términos vinculantes.
            </p>

            <h1>18. Garantías.</h1>
            <p>
              OFERTALO no se hace responsable de la identidad de los usuarios, de la seguridad en las entregas, así como de las condiciones de los productos que se ofrecen por terceros en la plataforma digital, tampoco se hace responsable de la calidad de los servicios que ofrecen terceros a través de su plataforma digital. No ofrece ninguna garantía expresa o implícita sobre los productos ofertados a través de OFERTALO.
            </p>

            <h1>19. SPAM y contenido malicioso.</h1>
            <p>
              Usted se abstendrá de usar en el sitio web la carga o transmisión de SPAM o mensajes no solicitados que contravengan la legislación mexicana aplicable; así como virus, troyanos, gusanos y cualquier otro componente malicioso.
            </p>

            <h1>20. Propiedad intelectual.</h1>
            <p>
              <strong>20.1.-</strong> Usted reconoce que OFERTALO es titular de los derechos de autor, marcas y patentes entorno a la Plataforma bajo el amparo de la legislación mexicana e internacional aplicable
            </p>
            <p>
              <strong>20.2.-</strong> Usted a través del acceso, generación de usuario, y uso en general de la Plataforma recibe licencia exclusiva, no transferible y no sub-licenciable para su uso en conformidad con los presentes términos y condiciones. Dicha licencia no representa permiso para arrendar, alquilar, transferir, otorgar licencias ni autorizar la copia total o parcial de la Plataforma en sí, signos distintivos, usuario, registro y/o cualquiera de sus componentes protegidos por la propiedad intelectual. La violación a dicha prohibición podrá llevar a la cancelación definitiva de usuario indistintamente de las acciones legales que pudieran tener lugar.
            </p>
            <p>
              <strong>20.3.-</strong> OFERTALO en posible calidad de licenciatario podrá constituirse como licenciante para la explotación de algunos de los componentes entorno a la Plataforma; dicha cuestión no le concede a usted autorización para hacer lo mismo.
            </p>
            <p>
              <strong>20.4.-</strong> El usuario reconoce que cuenta con la autoridad y facultades para usar las marcas, propiedad industrial, productos, servicios y en general bienes de intercambio que ofrezca en la plataforma y que los productos o servicios que ofrece o solicita no son fraudulentos, ni constituyen infracciones en materia de propiedad industrial incluido el delito de piratería de acuerdo a la legislación mexicana. De igual modo se obliga a eximir e indemnizar de cualquier tipo de responsabilidad a OFERTALO por cualquier contravención en dicho sentido. 
            </p>

            <h1>21. Vigencia.</h1>
            <p>
              La vigencia de este instrumento se entenderá indeterminada en tanto se utilice la Plataforma, exista una cuenta de usuario o siga vigente alguna contratación. Sin perjuicio por lo que pudiera subsistir de acuerdo a la política de privacidad de OFERTALO. Dicha terminación de vigencia de la contratación no supondrá el olvido de datos personales salvo mediante solicitud expresa.
            </p>

            <h1>22. Modificaciones.</h1>
            <p>
              Los presentes Términos y Condiciones podrán ser modificados libremente por OFERTALO con el propósito de brindar una atención lo más personalizada y actualizada posible a los usuarios. El Usuario se obliga a consultar los términos y condiciones del servicio cada vez que visite el sitio web con el fin de asegurarse de cualquier cambio en los mismos, indistintamente, OFERTALO se compromete a notificar las modificaciones pertinentes previo a su entrada en vigor.
            </p>

            <h1>23. Jurisdicción.</h1>
            <p>
              Cualquier procedimiento judicial relacionado con OFERTALO conforme a los presentes Términos y Condiciones se atenderá a la jurisdicción y legislación aplicable de los tribunales de Hermosillo, Sonora, México. Renunciando así a cualquier otra jurisdicción que pudiera corresponderle a usted en virtud de su domicilio presente o futuro.
            </p>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
