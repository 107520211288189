import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-[#ee2833] text-white -mx-8 px-8`
const HeadingContainer = tw.div`w-full lg:w-5/6 mx-auto`;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-white text-center text-sm lg:text-lg font-bold tracking-wider`;
const Description = tw(SectionDescription)`text-white text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-16 flex flex-row flex-wrap justify-center items-center mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 justify-center items-center`
const StatKey = tw.div`text-lg font-medium w-1/2`
const StatValue = tw.div`text-3xl sm:text-3xl md:text-4xl lg:text-5xl font-black w-1/2`
const Source = tw.p`text-center text-white mt-8`

export default ({
  subheading = "",
  heading = "Over 9000 Projects Completed",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  stats = [
    {
      key: "Del comercio minorista mundial fue a traves de internet en 2023 y se espera un aumento del 9% en 2024",
      value: "21%",
    },
    {
      key: "Es el crecimiento en 2024 de la busqueda de empleo por internet en Mexico",
      value: "25%",
    },
    {
      key: "Millones de dólares (USD) es el valor de las ventas por internet en Mexico en 2023",
      value: "33.420",
    },
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
        <Source>Fuente: <a href="https://www.eleconomista.com.mx" tw="text-white" target="_blank" rel="noopener noreferrer">El Economista</a></Source>
      </ContentWithPaddingXl>
    </Container>
  );
};
