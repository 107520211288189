import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import ContactUsPage from "pages/ContactUs.js";
import TermsOfServicePage from "pages/TermsOfService.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";
import ResetPassword from "pages/ResetPassword";

import LandingPage from "pages/LandingPage.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {


  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/contacto" element={<ContactUsPage />} />
          <Route path="/terminos-y-condiciones" element={<TermsOfServicePage />} />
          <Route path="/aviso-de-privacidad" element={<PrivacyPolicyPage />} />
          <Route path="/reestablecer-contrasena/:token" element={<ResetPassword />} />
        </Routes>
      </Router>
    </>
  );
}